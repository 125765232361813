// Types
import { INavIcon } from '../types/types';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BuildIcon from '@mui/icons-material/Build';

export const iconsData: INavIcon[] = [
  {
    title: 'global.repair',
    to: '/a/repair/management',
    icon: <BuildIcon />,
  },
  {
    title: 'global.repair',
    to: '/a/repair/reports',
    icon: <AssessmentIcon />,
  },
  {
    title: 'global.repair',
    to: '/a/repair/system',
    icon: <SettingsIcon />,
  },
];
